import { bindActionCreators } from 'redux'
import { showSpinner, clearUserData, showModal } from '../actions/index'
import store from '../services/store'
import Api from '../services/api'
import * as Socket from '../services/socket'

const bound = bindActionCreators({ showSpinner, clearUserData, showModal }, store.dispatch)

const logOutModule = {
  // the ONLY valid way to logout
  init: ({ force = false } = {}) => {
    if (force) {
      bound.showModal({
        title: 'Unauthorized',
        message: 'Looks like you are not authorized, please try to sign in again'
      })
    }
    bound.showSpinner(false)
    bound.clearUserData()
    window.localStorage.clear()
    window.sessionStorage.clear()
    Socket.disconnectAll()
    if (!force) {
      Api.userLogout()
    }
  }
}

export default logOutModule
