import React, { useEffect, useRef, useState } from 'react'
import { ResponsiveLine } from '@nivo/line'
import './styles.scss'
import { uniq } from 'lodash'

export const LineChart = ({ data }) => {
  const [minAverage, setMinAverage] = useState(-2)
  const [maxAverage, setMaxAverage] = useState(10)
  const [minWeight, setMinWeight] = useState(0)
  const [maxWeight, setMaxWeight] = useState(1)
  const [averageLine, setAverageLine] = useState([])
  const [fullWeightLine, setFullWeightLine] = useState([])
  const [tickValues, setTickValues] = useState([])
  const [containerWidth, setContainerWidth] = useState(0)
  const lineChartRef = useRef()

  const weightDataFormat = (max, array) => {
    const newWeightDataLine = [
      {
        index: 1,
        id: 'Full Weight (kg)',
        data: []
      }
    ]
    array.forEach(data => {
      newWeightDataLine[0].data.push(
        {
          x: data.date,
          y: data.weight
        }
      )
    })
    return newWeightDataLine
  }

  const averageDataFormat = (max, array) => {
    const newDataForAverageLine = [
      {
        index: 0,
        id: 'Average Daily Gain',
        data: []
      }
    ]
    array.forEach(data => {
      newDataForAverageLine[0].data.push(
        {
          x: data.date,
          y: data.averageDailyGain
        }
      )
    })
    return newDataForAverageLine
  }

  function findMinMax (arr) {
    let min = 0
    let max = 0
    if (arr.length) {
      min = Math.min(...arr[0].data.map(item => item.y))
      max = Math.max(...arr[0].data.map(item => item.y))
    }
    return [min, max]
  }

  useEffect(() => {
    setAverageLine(averageDataFormat(20, data))
    setFullWeightLine(weightDataFormat(20, data))
  }, [data])

  useEffect(() => {
    if (lineChartRef.current) {
      setContainerWidth(lineChartRef.current.offsetWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [lineChartRef])

  const handleResize = () => {
    if (lineChartRef.current) {
      setContainerWidth(lineChartRef.current.offsetWidth)
    }
  }

  useEffect(() => {
    const getTickValues = (innerWidth = 1060) => {
      const TICK_WIDTH = 100
      const xValues = fullWeightLine.map((d) => d.data.map((x) => x.x)).reduce((acc, data) => acc.concat(data), [])

      const gridWidth = Math.ceil(innerWidth / xValues.length)
      const tickDistance = Math.floor(TICK_WIDTH / gridWidth)
      let tickValues = tickDistance === 0 ? xValues : xValues.reverse().filter((_, i) => i % tickDistance === 0)
      tickValues = uniq(tickValues).reverse()
      return {
        tickValues: tickValues
      }
    }

    if (fullWeightLine.length) {
      setTickValues(getTickValues(containerWidth).tickValues)
    }
  }, [fullWeightLine, containerWidth])

  useEffect(() => {
    const [min, max] = findMinMax(averageLine)
    setMinAverage(min)
    setMaxAverage(max)
  }, [averageLine])

  useEffect(() => {
    const [weightMin, weightMax] = findMinMax(fullWeightLine)
    setMinWeight(weightMin)
    setMaxWeight(weightMax)
  }, [fullWeightLine])

  const AverageChart = () => {
    return (
      <ResponsiveLine
        data={averageLine}
        colors={['#FFD065']}
        margin={{ top: 50, right: 58, bottom: 50, left: 58 }}
        lineWidth={3}
        curve={'cardinal'}
        axisRight={{
          legend: 'Average Daily Gain',
          legendPosition: 'middle',
          legendOffset: 40,
          format: e => Math.floor(e) === e && e
        }}
        axisLeft={null}
        yScale={{ type: 'linear', min: minAverage, max: maxAverage, stacked: true, reverse: false }}
        enableGridY={false}
        enableGridX={false}
        axisBottom={null}
        enablePoints={false}
        enableArea
        legends={[
          {
            anchor: 'top',
            direction: 'column',
            justify: false,
            itemWidth: 100,
            itemHeight: 20,
            translateY: -30,
            translateX: -60,
            itemsSpacing: 4,
            symbolSize: 10,
            itemDirection: 'left-to-right',
            itemTextColor: '#777',
            data: [
              {
                id: 1,
                label: 'Average Daily Gain',
                color: '#FFD065'
              }
            ]
          }
        ]}
      />
    )
  }

  const FullWeightChart = () => (
    <ResponsiveLine
      data={fullWeightLine}
      colors={['#93BCEF']}
      margin={{ top: 50, right: 58, bottom: 50, left: 58 }}
      lineWidth={3}
      curve={'cardinal'}
      axisLeft={{
        legend: 'Full Weight (kg)',
        legendPosition: 'middle',
        legendOffset: -40
      }}
      yScale={{ type: 'linear', min: minWeight, max: maxWeight, stacked: true, reverse: false }}
      axisBottom={{
        tickValues: tickValues,
        tickRotation: -25,
        format: text => {
          if (text === fullWeightLine[0].data[0].x) {
            return null
          }
          return text
        }
      }}
      enablePoints={false}
      legends={[
        {
          anchor: 'top',
          direction: 'row',
          justify: false,
          translateY: -30,
          translateX: 60,
          itemWidth: 100,
          itemHeight: 20,
          itemsSpacing: 4,
          symbolSize: 10,
          itemDirection: 'left-to-right',
          itemTextColor: '#777',
          data: [
            {
              id: 1,
              label: 'Full Weight (kg)',
              color: '#93BCEF'
            }
          ]
        }
      ]}
    />
  )

  return (
    <div style={{ height: 350 }}>
      <div className='wrapper'>
        <div ref={lineChartRef} className='graphContainer'>
          <FullWeightChart />
        </div>
        <div className='secondGraph'>
          <AverageChart />
        </div>
      </div>
    </div>
  )
}
