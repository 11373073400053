import moment from 'moment-timezone'
import qs from 'qs'

export function mergeByProperty (arr1, arr2, prop = '_id') {
  const newArr = [...arr1]
  if (!Array.isArray(arr2)) {
    arr2 = [arr2]
  }
  arr2.forEach(arr2obj => {
    const arr1obj = newArr.find(elem => {
      return elem[prop] === arr2obj[prop]
    })
    const arr1objIndex = newArr.indexOf(arr1obj)
    // If object already exists, extend it with new values from arr2, otherwise just add new object to arr1
    if (arr1obj) {
      let canReplace = true
      // if new data has 'updatedAt' value lower than old info
      // do not allow new data to replace old data
      if (Object.prototype.hasOwnProperty.call(arr1obj, 'updatedAt') && Object.prototype.hasOwnProperty.call(arr2obj, 'updatedAt') &&
        new Date(arr1obj.updatedAt) > new Date(arr2obj.updatedAt)) {
        canReplace = false
      }

      newArr[arr1objIndex] = Object.assign({}, arr1obj)
      Object.entries(arr2obj).forEach(([key, value]) => {
        if (canReplace || Object.prototype.hasOwnProperty.call(!newArr[arr1objIndex], key)) {
          newArr[arr1objIndex][key] = value
        }
      })
    } else {
      newArr.push(arr2obj)
    }
  })
  return newArr
}

export function pushToUniqueArray (arr1, values) {
  return Array.from(new Set(arr1.concat(values)))
}

// if array exceeds maxLength, remove oldest values until array.length === maxLength
export function limitArray (arr, maxLength) {
  return arr.slice(arr.length > maxLength ? arr.length - maxLength : 0)
}

export function calculateLimitTextLength (text, maxLength) {
  let descriptionLimit = maxLength
  let offset = -1
  if (text && (text.length > descriptionLimit)) {
    offset = text.substr(0, maxLength).lastIndexOf(' ')
  }
  if (offset !== -1) {
    descriptionLimit -= (descriptionLimit - offset)
  }
  return descriptionLimit
}

export function limitTextLength (text, maxLength, withSpread = true) {
  if (!text) {
    return ''
  }
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + `${withSpread ? '...' : ''}`
  } else {
    return text
  }
}

export function limitCharacters (text) {
  if (text) {
    if (text.length > 25) {
      return `${text.slice(0, 15)}...${text.slice(text.length - 7, text.length)}`
    } else {
      return text
    }
  }
}

export function momentTz (date, timezone = 'Australia/Sydney') {
  return moment(date).tz(timezone)
}

export function clone (obj) {
  let temp
  if (obj === null || typeof (obj) !== 'object' || 'isActiveClone' in obj) {
    return obj
  }
  if (obj instanceof Date) {
    temp = new obj.constructor() // or new Date(obj);
  } else {
    temp = obj.constructor()
  }
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      obj['isActiveClone'] = null
      temp[key] = clone(obj[key])
      delete obj['isActiveClone']
    }
  }
  return temp
}

export function arrayBufferToBase64 (buffer) {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

export function alphabeticalSort (arr) {
  return arr.slice().sort((a, b) => {
    const nameA = a.toLowerCase()
    const nameB = b.toLowerCase()
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  })
}

export function isElementInsideOf (child, parent) {
  // also returns true if child === parent
  return parent &&
    (parent === child || parent.contains(child))
}

// takes location.search string e.g. '?from=watchlist'
// returns object with query params e.g. {from: 'watchlist'}
export function parseQueryString (queryString) {
  // trim '?' symbol at the start of query string if present
  if (/^\?/.test(queryString)) {
    queryString = queryString.slice(1)
  }
  return qs.parse(queryString)
}

export function checkFilterIntersection (container, cls) {
  if (container && window.innerWidth >= 550) {
    const offsetContainer = container.getBoundingClientRect()
    if (offsetContainer.x < 0) {
      container.classList.remove(cls)
    }
    if (offsetContainer.x + offsetContainer.width + 20 >= window.innerWidth) {
      container.classList.add(cls)
    }
  }
}
