import React, { PureComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faTimes
} from '@fortawesome/fontawesome-free-solid'

import { limitTextLength } from '../../utils/index'

class Modal extends PureComponent {
  /*
    this.props.data = {
      title: 'title',
      message: 'message',
      buttons: [
        {
          text: 'btn1',
          onPress: () => {}
        },
        {
          text: 'btn2',
          onPress: () => {}
        }
      ]
    }
  */
  componentDidUpdate (prevProps, prevState) {
    if (this.props.active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }

  getButtons () {
    const { data: { buttons }, hideModal } = this.props
    return (buttons && buttons.length)
      ? (
        buttons.map((obj, i) => {
          return <div className='modal-button custom' key={i}
            onClick={() => {
              if (!obj.isNotClose) {
                hideModal()
              }
              obj.onPress && obj.onPress()
            }}>
            {obj.text}
            {
              obj.isSuccess &&
              <span className='icon-wrapper icon-success'>
                <FontAwesomeIcon icon={faCheck} />
              </span>
            }
            {
              obj.isCancel &&
              <span className='icon-wrapper icon-red'>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            }
          </div>
        })
      )
      : (
        <div className='modal-button default' onClick={() => { hideModal() }}>OK</div>
      )
  }

  render () {
    const { data: { title, message, customBodyJSX, customClassName = '', topBar = false }, active } = this.props
    if (!active) {
      return <div />
    }
    return (
      <div className={`app-modal-overlay ${customClassName}`}>
        <div className={`app-modal ${customClassName === "registerInterest-success-modal" ? customClassName : "" }`}>
          {title && <p className='title'>{limitTextLength(title, 250)}</p>}
          { topBar &&
            <div className='buttons'>
              {this.getButtons()}
            </div>
          }
          <p className='message'>{message || 'Message'}</p>
          {customBodyJSX && customBodyJSX()}
          { !topBar &&
            <div className='buttons'>
              {this.getButtons()}
            </div>
          }
        </div>
      </div>
    )
  }
}

export default Modal
