import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { upcomingAuctions } from '../../selectors/auctionsSelector'
import { upcomingLots } from '../../selectors/lotsSelector'
import AuctionLits from './AuctionList'
import { changeStateProp, showModal, mergeStateProp,showSpinner } from '../../actions/index'

const mapStateToProps = (state) => {
  return {
    auctions: upcomingAuctions({
      auctions: state.data.auctions
    }),
    lots: upcomingLots({
      //auctions: state.data.auctions,
      lots : state.data.lots
    }),
    locationStates: state.data.locationStates,
    userStatus: state.user.data.status,
    filters: state.user.filters
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeStateProp, showModal, mergeStateProp,showSpinner }, dispatch)
}

const AuctionsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuctionLits)

export default AuctionsListContainer
