import update from 'immutability-helper'

import {
  CHANGE_STATE_PROP,
  SHOW_SINGLE_NOTIFICATION,
  HIDE_SINGLE_NOTIFICATION,
  CLOSE_SINGLE_NOTIFICATION,
  UPDATE_LOT_INFO
} from '../constants/index'
import { pushToUniqueArray } from '../utils'

const REDUCER = 'MAIN'

const initialState = {
  lotSort: {
    choice: 'Lot Number',
    direction: 'down'
  },
  signUpInfo: {
    buyer: null,
    seller: null
  },
  singleNotifications: {
    active: [],
    closed: []
  },
  lotsInfo: {}
}
export default (state = initialState, action) => {
  switch (action.type) {
    case REDUCER + CHANGE_STATE_PROP:
      return update(state, {
        [action.state.prop]: { $set: action.state.value }
      })
    // show notification if it has not been closed yet
    case SHOW_SINGLE_NOTIFICATION:
      if (state.singleNotifications.closed.includes(action.key)) {
        return state
      }
      return update(state, {
        singleNotifications: {
          active: (prop) => pushToUniqueArray(prop, [action.key])
        }
      })
    // hide notification if it is showing (can be shown next time)
    case HIDE_SINGLE_NOTIFICATION:
      if (!state.singleNotifications.active.includes(action.key)) {
        return state
      }
      return update(state, {
        singleNotifications: {
          active: (keys) => keys.filter(key => key !== action.key)
        }
      })
    // close notification (can not be shown next time)
    case CLOSE_SINGLE_NOTIFICATION:
      return update(state, {
        singleNotifications: {
          active: (keys) => keys.filter(key => key !== action.key),
          closed: (prop) => pushToUniqueArray(prop, [action.key])
        }
      })
    case UPDATE_LOT_INFO:
      return update(state, {
        lotsInfo: {
          [action.lotId]: lotData => {
            return update(lotData || {}, { $merge: action.data })
          }
        }
      })
    default:
      return state
  }
}
