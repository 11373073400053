import React, { PureComponent } from 'react'
import { Provider } from 'react-redux'
import { withCookies } from 'react-cookie'
import { Switch, Route, Redirect} from 'react-router-dom'
import * as UpdateData from '../src/utils/UpdateData'
import Api from '../src/services/api'
import { BrowserRouter as Router } from 'react-router-dom'
import AuctionListContainer from './components/AuctionList/AuctionListContainer'
import LotsListContainer from './components/AuctionList/LotsList/LotsListContainer'
import LotDetailsContainer from './components/AuctionList/LotDetails/LotDetailsContainer'
import ModalContainer from './components/Modal/ModalContainer'
import ReactGA from 'react-ga'
import './App.scss'
import './App.css'
import { onEnter } from '../src/utils/UpdateData'


const errorSettingsRefresh = 20000
class App extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false
    }
    this.checkInterval = null
  }

  static async getDerivedStateFromProps() {
    await Api.checkServer().then(async response => {
      if (response.ok) {
        let json = await response.json()
        if (json.isLive) {
          UpdateData.updateInitialData()
        }
      } else {
        throw new Error()
      }
    })
    return null
  }

  async componentDidMount() {
   // checkRedirectForIos()
    const { cookies } = this.props
    cookies.set('BackUpServer', true)
    this.checkServerBackUp()
   // let trackingId = MODE === 'live' ? 'UA-137496646-3' : 'UA-137496646-1'
    this.checkInterval = setInterval(() => { this.checkServerBackUp() }, errorSettingsRefresh)
     ReactGA.initialize('UA-137496646-2')
    if (window.location.href.includes('&fbclid')) {
      window.location.href = window.location.href.split('&fbclid')[0]
    }
    if (window.location.href.includes('%2F')) {
      window.location.href = window.location.href.replace('%2F', '/')
    }
    await Api.checkServer().then(async response => {
      if (response.ok) {
        let json = await response.json()
        if (json.isLive) {
            await UpdateData.updateMainData()
            await UpdateData.updateInitialData()
        }
      } else {
        throw new Error()
      }
    })
    
    }
    componentWillUnmount () {
      clearInterval(this.checkInterval)
    }
    checkServerBackUp = async () => {
      const { cookies } = this.props
      await Api.checkServer().then(async response => {
        if (response.ok) {
          let json = await response.json()
          if (json.isLive) {
            if (!cookies.cookies.BackUpServer) {
              setTimeout(async () => {
                cookies.set('BackUpServer', true)
                this.props.changeStateProp('isServerLiveMessage', json.message, 'temp')
                this.props.changeStateProp('isServerLive', json.isLive, 'temp')
              }, errorSettingsRefresh)
            } else {
              this.props.changeStateProp('isServerLiveMessage', json.message, 'temp')
              this.props.changeStateProp('isServerLive', json.isLive, 'temp')
            }
          } else {
            cookies.set('BackUpServer', false)
            this.props.changeStateProp('isServerLiveMessage', json.message, 'temp')
            this.props.changeStateProp('isServerLive', json.isLive, 'temp')
          }
        } else {
          throw new Error()
        }
      }).catch(err => {
        console.log('Err: ', err)
      })

  }

  render() {
    return (
      <Provider store={this.props.store}>
        <Router >
          <div className='App'>
            <div className='app-route'>
              <div className='inner-container'>
              {onEnter(               
                 <Switch>
                  <Route path='/' exact component={AuctionListContainer} />
                  <Route path='/main/upcoming/:kindTitle/:auctionNumber' exact component={LotsListContainer} />
                  <Route path='/main/upcoming/:kindTitle/:auctionNumber/:lotNumericId' exact component={LotDetailsContainer} />
                  <Route path='/main/upcoming/:kindTitle/:auctionNumber/:lotNumericId/agent-comment' exact component={LotDetailsContainer} />
                  <Redirect to='/' />
                </Switch>,
                this.props.isServerLive)}

              </div>
              <ModalContainer />
            </div>
          </div>
        </Router>
      </Provider>
    )
  }
}
App.defaultProps = {}
export default withCookies(App)
