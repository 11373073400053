import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { persistReducer } from 'redux-persist'
import localForage from 'localforage'
import thunk from 'redux-thunk'
import { mainReducer, userReducer, dataReducer, tempReducer } from '../reducers/index'

const rootPersistConfig = {
  key: 'root-v0.8.9',
  storage: localForage,
  blacklist: ['data', 'user', 'temp']
}

const userPersistConfig = {
  key: 'user-v0.8.9',
  storage: localForage,
  blacklist: ['data']
}

const dataPersistConfig = {
  key: 'data-v0.8.9',
  storage: localForage,
  blacklist: ['auctions', 'lots']
}

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  data: persistReducer(dataPersistConfig, dataReducer),
  main: mainReducer,
  temp: tempReducer
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  undefined,
  compose(
    applyMiddleware(...[thunk])
  )
)

// for dev purposes
// window.store = store

export default store
