import { connect } from 'react-redux'
import ImageCarousel from './ImageCarousel'
import { changeStateProp } from '../../actions'
import { bindActionCreators } from 'redux'

const mapStateToProps = (state) => {
  return {
    imageCarouselModal: state.temp.imageCarouselModal
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeStateProp }, dispatch)
}

const ImageCarouselContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageCarousel)

export default ImageCarouselContainer
