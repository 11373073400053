import React from 'react'
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share'

const sizeImage = 45
const radius = 5
const styleButton = { padding: 0, marginLeft: 10, height: sizeImage, borderRadius: radius }
const SocialButtons = props => (
  <>
    <TwitterShareButton
      url={props.shareUrl}
      windowWidth={window.outerWidth}
      windowHeight={window.outerHeight}
      title={props.shareMessage}
      className='network-share-button'
      style={styleButton}
    >
      <TwitterIcon size={sizeImage} borderRadius={radius} />
    </TwitterShareButton>
    <FacebookShareButton
      url={props.shareUrl}
      windowWidth={window.outerWidth}
      windowHeight={window.outerHeight}
      className='network-share-button'
      style={styleButton}
    >
      <FacebookIcon size={sizeImage} borderRadius={radius} />
    </FacebookShareButton>
  </>
)

export default SocialButtons
