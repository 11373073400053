import React from 'react'
import PropTypes from 'prop-types'

const FilterMultiItem = props => {
  const { option, checkHandler, uncheckHandler, isSelected } = props
  return (
    <label key={option}>
      <span>{option}</span>
      <span className={`filter-checkbox ${isSelected && 'active'}`} />
      <input type='radio' name='filter-day' onClick={() => {
        if (isSelected) {
          uncheckHandler(option)
        } else {
          checkHandler(option)
        }
      }} />
    </label>
  )
}

FilterMultiItem.propTypes = {
  option: PropTypes.string.isRequired,
  checkHandler: PropTypes.func.isRequired,
  uncheckHandler: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired
}

export default FilterMultiItem
