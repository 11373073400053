import { connect } from 'react-redux'

import DetailedInfo from './DetailedInfo'

const mapStateToProps = (state) => {
  return {
    userId: state.user.data._id
  }
}

export default connect(
  mapStateToProps,
  null
)(DetailedInfo)
