import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDown,
  faFilePdf,
  faTable
} from '@fortawesome/fontawesome-free-solid'
import { isElementInsideOf } from '../../utils'

class DownloadResultBtn extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }

    this.open = this.open.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)

    this.innerContainer = React.createRef()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.isOpen !== this.state.isOpen) {
      if (this.state.isOpen) {
        document.addEventListener('click', this.handleDocumentClick)
      } else {
        document.removeEventListener('click', this.handleDocumentClick)
      }
    }
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.handleDocumentClick)
  }

  open () {
    const state = !this.state.isOpen
    this.setState({ isOpen: state })
  }

  handleDocumentClick (event) {
    if (!this.state.isOpen ||
      isElementInsideOf(event.target, this.innerContainer.current)) {
      return
    }
    this.setState({ isOpen: false })
  }

  renderItems () {
    return (
      <div>
        <a className='item' href={this.props.pdfUrl}>
          <span>Download as PDF</span>
          <span className='icon-wrapper icon-danger icon-md'><FontAwesomeIcon icon={faFilePdf} /></span>
        </a>
        <a className='item' href={this.props.csvUrl}>
          <span>Download as CSV</span>
          <span className='icon-wrapper icon-danger icon-md'><FontAwesomeIcon icon={faTable} /></span>
        </a>
      </div>
    )
  }

  render () {
    return (
      <div className='download-wrapper'>
        <div className='btn download-btn' onClick={this.open.bind(this)}>
          <span>{this.props.name}</span>
          <span className='icon-wrapper icon-warning'><FontAwesomeIcon icon={faArrowDown} /></span>
        </div>
        <div className={`inner-wrapper ${this.state.isOpen ? 'active' : ''}`}>
          { this.renderItems() }
        </div>
      </div>
    )
  }
}

DownloadResultBtn.defaultProps = {
  name: 'Download Results',
  pdfUrl: '/',
  csvUrl: '/'
}

DownloadResultBtn.propTypes = {
  name: PropTypes.string,
  pdfUrl: PropTypes.string,
  csvUrl: PropTypes.string
}

export default DownloadResultBtn
