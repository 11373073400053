import React from 'react'
import PropTypes from 'prop-types'
import DownloadResultBtn from '../DownloadResultBtn/DownloadResultBtn'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/fontawesome-free-solid'

class DownloadCatalogueBtn extends DownloadResultBtn {
  renderItems () {
    return (
      <div>
        <a className='item' href={this.props.summaryUrl}>
          <span>Summary Catalogue</span>
          <span className='icon-wrapper icon-danger icon-md'><FontAwesomeIcon icon={faFilePdf} /></span>
        </a>
        <a className='item' href={this.props.fullDetailsUrl}>
          <span>Lots in Full Details</span>
          <span className='icon-wrapper icon-danger icon-md'><FontAwesomeIcon icon={faFilePdf} /></span>
        </a>
      </div>
    )
  }
}

DownloadCatalogueBtn.defaultProps = {
  name: 'Download Catalogue',
  summaryUrl: '/',
  fullDetailsUrl: '/'
}

DownloadCatalogueBtn.propTypes = {
  name: PropTypes.string,
  summaryUrl: PropTypes.string,
  fullDetailsUrl: PropTypes.string
}

export default DownloadCatalogueBtn
