import styled from 'styled-components'
import media from 'styled-media-query'

export const BoxesContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 45px;
  ${media.lessThan('1035px')`
  flex-direction: column;
  `}
  `
