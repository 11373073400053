/* global FileReader */
import moment from 'moment'
import ImageCompressor from '@xkeshi/image-compressor'

import ServerTime from '../services/ServerTime'
import { alphabeticalSort } from './index'
import store from '../services/store'
import {
  bidTypes,
  LOT_TIMER_ENDING_THRESHOLD
} from '../constants'

const imageCompressor = new ImageCompressor()

export function getRunningStatus (lot, auction, userId = null) {
  if (!auction) {
    return 'none'
  }
  if (lot.state === 'closed') {
    return 'none'
  }
  if (lot.state === 'open') {
    return 'none'
  }
  if (lot.state === 'future') {
    return 'none'
  }

  const diff = lot.staleTime || moment(lot.finishAt).valueOf() - moment().valueOf()
  const isEnding = diff < LOT_TIMER_ENDING_THRESHOLD

  if (isEnding) {
    if (isUserWinner(lot.participants, userId, lot.winner)) {
      return 'bided-ending'
    } else if (isUserParticipant(lot.participants, userId, lot.winner) && !isUserWinner(lot.participants, userId, lot.winner)) {
      return 'overbided-ending'
    } else {
      return 'ending'
    }
  }
  if (isUserWinner(lot.participants, userId, lot.winner)) {
    return 'bided'
  } else if (isUserParticipant(lot.participants, userId, lot.winner) && !isUserWinner(lot.participants, userId, lot.winner)) {
    return 'overbided'
  } else {
    return 'none'
  }
}

export function getResultMessage (lot, userId) {
  if (lot.withdrawn) {
    return 'withdrawn'
  }
  if (lot.state !== 'closed') {
    return ''
  }
  switch (lot.status) {
    case 'noBid':
      return 'no bid'
    case 'passed':
      return 'passed'
    case 'sold':
      if (lot.participants.find(id => id === userId)) {
        if (userId === lot.winner) {
          return 'won'
        } else {
          return 'lost'
        }
      } else {
        return 'sold'
      }
    default:
      // default case just for safety, should not actually appear in live auction
      return 'closed'
  }
}

export function getTimerState (lot, timeLeft) {
  let state = null
  if (lot) {
    if (lot.withdrawn) {
      state = null
    } else {
      switch (lot.state) {
        case 'future':
          state = 'FUTURE'
          break
        case 'open':
          state = 'OPEN'
          break
        case 'closed':
          state = 'CLOSED'
          break
        case 'live':
        default:
          state = formatTimeLeft(timeLeft)
      }
    }
  }
  return state
}

export function getMyBid (lot, bids) {
  return bids.find(bid => bid.lot === lot._id)
}

export function getMyAutobid (lot, autobids) {
  return autobids.find(autobid => autobid.lot === lot._id)
}

export function makePrice (price, { bidding, cents = false, precision = 2 } = {}) {
  // handle edge cases e.g. Infinity
  if (!Number.isFinite(price)) {
    return null
  }
  if (bidding && bidding === bidTypes.KG) {
    cents = true
  }
  if (cents) {
    return parseFloat(price.toFixed(precision)) + ' ¢'
  }
  return '$' + (price / 100).toFixed(precision)
}

export function isUserParticipant (participants, userId, winner) {
  // do not count user as participant if lot does not have a winner
  return winner && participants.some(user => user === userId)
}

export function isUserWinner (participants, userId, winner) {
  return userId === winner && isUserParticipant(participants, userId, winner)
}

export function formatTimeLeft (timeLeft) {
  if (timeLeft.done) {
    return 'CLOSING...'
  }
  return timeLeft.hours > 0
    ? `${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`
    : `${timeLeft.minutes}m ${timeLeft.seconds}s`
}

export function isAvailableEditLate (lot, auction) {
  if (!lot || !auction) {
    return false
  }
  if (lot.approved) {
    return ServerTime.getTime() < new Date(auction.editLateLotDisabledAt)
  } else {
    return false
  }
}

export function compressImage (file, options) {
  return new Promise((resolve, reject) => {
    imageCompressor.compress(file.file, options)
      .then((result) => {
        const reader = new FileReader()
        reader.readAsDataURL(result)
        reader.onloadend = function () {
          file.url = reader.result
          file.mimeType = 'image/jpeg'
          delete file.blob
          resolve(file)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function getSexesByKinds (kindTypes, { sort = false } = {}) {
  if (!Array.isArray(kindTypes)) {
    kindTypes = [kindTypes]
  }
  const stockCategories = store.getState().data.stockCategories
  const kindCategoriesArr = kindTypes.map(kindType => {
    const kindCategories = stockCategories[kindType] || stockCategories.cattle
    return Object.keys(kindCategories)
  })
  let sexes = [].concat(...kindCategoriesArr)
  // remove any duplicates with Set
  sexes = Array.from(new Set(sexes))
  if (sort) {
    sexes = alphabeticalSort(sexes)
  }
  return sexes
}

export function getStockCategoriesByKinds (kindTypes, { sort = false } = {}) {
  if (!Array.isArray(kindTypes)) {
    kindTypes = [kindTypes]
  }
  const stockCategories = store.getState().data.stockCategories
  const kindCategoriesArr = kindTypes.map(kindType => {
    const kindCategories = stockCategories[kindType] || stockCategories.cattle
    return [].concat(...Object.values(kindCategories))
  })
  let categories = [].concat(...kindCategoriesArr)
  // remove any duplicates with Set
  categories = Array.from(new Set(categories))
  if (sort) {
    categories = alphabeticalSort(categories)
  }
  return categories
}

export function getStockCategoriesBySex (kindType, sex, { sort = false } = {}) {
  const stockCategories = store.getState().data.stockCategories
  const kindCategories = stockCategories[kindType] || stockCategories.cattle
  let categories = kindCategories[sex] || []
  if (sort) {
    categories = alphabeticalSort(categories)
  }
  return categories
}

export function getDistrictsByState (state, { sort = false } = {}) {
  const locationDistricts = store.getState().data.locationDistricts
  let stateDistricts = locationDistricts[state] || []
  if (sort) {
    stateDistricts = alphabeticalSort(stateDistricts)
  }
  return stateDistricts
}

export function convertDollarToCentsPrice (price, lot) {
  return price / lot.publicDetails.weight.average
}

export function convertCentsToDollarPrice (price, lot) {
  return price * lot.publicDetails.weight.average
}

export function calculateAverageDailyWeightGain (lot) {
  if (lot.optiweighAllowed && lot.optiweigh && lot.optiweigh.averageData) {
    const res = lot.optiweigh.averageData.reduce((acc, value) => (acc + value.averageDailyGain), 0) / lot.optiweigh.averageData.length
    return parseFloat(res.toFixed(2))
  }
}
