import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

import Api from '../../services/api'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faTimes
} from '@fortawesome/fontawesome-free-solid'

class EmailModalLot extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      recipientName: this.props.userData.firstName,
      recipientEmail: this.props.userData.email
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.changeDocType = this.changeDocType.bind(this)
    this.sendEmailRequest = this.sendEmailRequest.bind(this)
    this.title = 'Email Lot Details'
  }

  handleInputChange ({ target: { name, value } }) {
    this.setState({
      [name]: value
    })
  }

  changeDocType (docType) {
    this.setState({
      docType
    })
  }

  async sendEmailRequest () {
    const data = {
      recipientName: this.state.recipientName,
      recipientEmail: this.state.recipientEmail
    }

    this.props.showSpinner(true)
    const res = await this.sendRequest(data)
    if (res && res.success) {
      this.props.showModal({
        title: 'FarmGate',
        message: res.success
      })
      this.props.closeModal()
    }
    this.props.showSpinner(false)
  }

  async sendRequest (data) {
    const req = this.props.owner ? Api.emailLotOwnerInfo : Api.emailLotInfo
    const res = await req({
      lotId: this.props.lot._id,
      data
    })
    return res
  }

  render () {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onAfterOpen={this.props.afterOpenModal}
        onRequestClose={this.props.closeModal}
        ariaHideApp={false}
        // shouldCloseOnOverlayClick={false}
        overlayClassName='overlay'
        className='content'
        portalClassName='ReactModalPortal-Email'
      >

        <h2 className='modal-header'>{this.title}</h2>
        <span className='icon-close icon-danger' onClick={this.props.closeModal}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <div className='modal-body'>
          <input
            type='text'
            name='recipientName'
            placeholder='Name'
            className='modal-input'
            onChange={this.handleInputChange}
            defaultValue={this.state.recipientName} />
          <input
            type='text'
            name='recipientEmail'
            placeholder='Email'
            className='modal-input'
            onChange={this.handleInputChange}
            defaultValue={this.state.recipientEmail} />
          <div className='buttons'>
            <div className='button' onClick={this.sendEmailRequest}>
              <span className='button-text'>Send</span>
              <span className='icon-wrapper'><FontAwesomeIcon icon={faEnvelope} /></span>
            </div>
          </div>
        </div>

      </Modal>
    )
  }
}

EmailModalLot.propTypes = {
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  afterOpenModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  lot: PropTypes.object,
  owner: PropTypes.bool.isRequired
}

EmailModalLot.defaultProps = {
  afterOpenModal: () => {},
  owner: false
}

export default EmailModalLot
