import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import App from './App'
import { changeStateProp, showModal } from './actions'

const mapStateToProps = (state) => {
  return {
    loggedIn: state.user.loggedIn,
    rememberMe: state.user.rememberMe
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeStateProp, showModal }, dispatch)
}

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App)

export default AppContainer
