import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark } from '@fortawesome/fontawesome-free-solid'
import { WEB_APP } from '../../config/index'
class WatchButton extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      active: this._isWatched()
    }
    this.toggle = this.toggle.bind(this)
  }

  componentDidMount () {
    this.updateState()
  }

  componentDidUpdate () {
    if (this.state.active !== this._isWatched()) {
      this.updateState()
    }
  }

  updateState () {
    this.setState({
      active: this._isWatched()
    }, () => {
      this.props.onChange(this.state.active)
    })
  }

  async toggle (e) {
    if (!this.props.bigButton) {
      e.stopPropagation()
    }
    const url = `${WEB_APP}/${document.location.hash}?lot-watch-id=${this.props.lotId}`
    window.parent.parent.postMessage(`Redirect for url-=-${url}`, '*')
  }

  _isWatched () {
    return Boolean(
      this.props.userData.watchList &&
      this.props.userData.watchList.includes(this.props.lotId)
    )
  }

  render () {
    const { bigButton, list } = this.props
    const { active } = this.state
    if (bigButton) {
      return (
        <div
          className={`watching-btn${active ? ' active' : ''} ${list ? 'list' : ''}`}
          onClick={this.toggle}
        >
          <span>Log in to Watch</span>
          <span className='icon-wrapper'><FontAwesomeIcon icon={faBookmark} /></span>
        </div>
      )
    } else {
      return (
        <div
          className='watching-small-btn'
          onClick={this.toggle}>
          <FontAwesomeIcon className={`${active ? 'active' : ''} watch-flag`} icon={faBookmark} />
        </div>
      )
    }
  }
}

WatchButton.globalRequestThrottleMs = 600
WatchButton.globalRequestTimeout = null

WatchButton.propTypes = {
  userData: PropTypes.object.isRequired,
  lotId: PropTypes.string.isRequired,
  bigButton: PropTypes.bool,
  onChange: PropTypes.func
}

WatchButton.defaultProps = {
  bigButton: true,
  onChange: () => {}
}

export default WatchButton
