import React, { PureComponent } from 'react'

export default class LotItemWithdrawn extends PureComponent {
  render () {
    const { lot } = this.props
    return (
      <div className='lot-item lot-item-withdrawn' key={lot._id}>
        <div className='lot-img-container lot-img-container-withdrawn' />
        <div className='lot-info'>
          <div className='lot-info-top'>
            <p className='lot-number'>Lot {lot.number || 'X'} - Withdrawn from Auction</p>
          </div>
          <div className='lot-info-middle'>
            <div className='left'>
              <p className='lot-title'>{lot.publicDetails.titleLive}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
