import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { auctionByNumber } from '../../../selectors/auctionsSelector'
import { auctionPublicLots } from '../../../selectors/lotsSelector'
import { changeStateProp, showModal, mergeStateProp, showSpinner } from '../../../actions'
import LotsList from './LotsList'

const mapStateToProps = (state, ownProps) => {
  const auction = auctionByNumber({
    auctions: state.data.auctions,
    auctionNumber: ownProps.match.params.auctionNumber
  })
  const lots = auctionPublicLots({
    auction: auction,
    lots: state.data.lots
  })
  return {
    kinds: state.data.kinds,
    auction,
    lots,
    locationStates: state.data.locationStates,
    sortChoice: state.main.lotSort.choice,
    sortDirection: state.main.lotSort.direction,
    userId: state.user.data._id,
    filters: state.user.filters,
    userStatus: state.user.data.status
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeStateProp, showModal, mergeStateProp, showSpinner }, dispatch)
}

const LotsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LotsList)

export default LotsListContainer
