import { BarChart } from '../BarChart/BarChart'
import React from 'react'
import { ScoreChart } from '../ScoreChart/ScoreChart'

const IndividualAssessment = ({ data }) => {
  return (
    <div className='lot-documents'>
      <div className='row'>
        <div className='row-title-wrap'>
          <p className='row-title'>Individual Assessment</p>
        </div>
        <div className='individual-assessment-container'>
          <div className='optiweigh-container'>
            <ScoreChart fatScoreData={data.scores} />
          </div>
          <div className='divider' />
          <div className='optiweigh-container'>
            <BarChart individualAssessmentData={data} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default IndividualAssessment
