import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import moment from 'moment'
import './prewiev-image.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimes
} from '@fortawesome/fontawesome-free-solid'

import Carousel from '../Carousel/Carousel'
import { limitTextLength } from '../../utils'

const PrewievModalImages = React.memo(({ closeModal, isOpen, lot, lotMedia, preview, numberOfSlides, indexImg, listType, lotList }) => {
  if (lot !== null && lot && lot.media && lot.media.length > 0) {
    let top = ''
    let heightOfLot, marginBottomOfLot, totalHeight
    if (lotList && lotList.length) {
      const lotNumber = lotList.findIndex((elem) => {
        return lot.number === elem.number
      })
      if (window.innerWidth >= 900) {
        if (listType === 'list') {
          heightOfLot = 247
          marginBottomOfLot = 25
          totalHeight = heightOfLot + marginBottomOfLot
          top = lotNumber !== lotList.length - 1 ? (200 + (totalHeight * lotNumber)) + 'px' : (totalHeight * lotNumber) + 'px'
        } else if (listType === 'grid') {
          let numberOfLotsInOneLine
          if (window.innerWidth > 1100) {
            numberOfLotsInOneLine = 3
            heightOfLot = 783
          } else if (window.innerWidth < 1100 && window.innerWidth >= 900) {
            numberOfLotsInOneLine = 2
            heightOfLot = 681
          }
          marginBottomOfLot = 25
          totalHeight = heightOfLot + marginBottomOfLot
          top = (300 + (totalHeight * Math.floor(lotNumber / numberOfLotsInOneLine))) + 'px'
        } else {
          top = '200px'
        }
      } else if (window.innerWidth <= 900 && window.innerWidth >= 550) {
        heightOfLot = 517
        marginBottomOfLot = 25
        totalHeight = heightOfLot + marginBottomOfLot
        top = (400 + (totalHeight * lotNumber)) + 'px'
      } else {
        heightOfLot = 619
        marginBottomOfLot = 25
        totalHeight = heightOfLot + marginBottomOfLot
        top = (500 + (totalHeight * lotNumber)) + 'px'
      }
    } else {
      top = '200px'
    }
    if (isOpen) {
      window.parent.parent.postMessage(`modalOpen`, '*')
      const container = document.querySelector('.inner-container')
      if (container) {
        container.style.maxWidth = null
      }
    }
    return (
      <Modal
        style={{ overlay: {
          top,
          maxHeight: window.innerHeight
        } }}
        isOpen={isOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        overlayClassName='overlayForPrewiev'
        portalClassName='ReactModalPortal-Email'
      >
        <span className='icon-close icon-danger icon-for-prewiev' onClick={closeModal}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <div className='preview-carousel'>
          <Carousel
            key={lot._id}
            preview={preview}
            numberOfSlides={numberOfSlides}
            carouselClassName={preview ? 'preview' : null}
            currentSlide={indexImg}
          >
            {lotMedia.map((file, index) => {
              switch (true) {
                case /image/.test(file.mimeType):
                  return (
                    <div className='carousel-item' key={file.key}>
                      <img className='carousel-img' src={file.url} alt='' />
                      {file.timestamp && !preview && <span className='date'>{moment(file.timestamp).format('DD/MM/YYYY')}</span>}
                    </div>
                  )
                case /video/.test(file.mimeType):
                  return (
                    <div className={file.title ? 'carousel-item with-title' : 'carousel-item'} key={index}>
                      {preview
                        ? (
                          <img className='carousel-img video-thumbnail' src={file.thumbnail} alt='' />
                        )
                        : (
                          <video className={file.title ? 'carousel-video with-title' : 'carousel-video'} poster={file.thumbnail} controls>
                            <source src={file.url} type={file.type} />
                          </video>
                        )}
                      {file.title && (<div className='video-title'>{limitTextLength(file.title, 60)}</div>)}
                      {file.timestamp && !preview && <span className='date'>{moment(file.timestamp).format('DD/MM/YYYY')}</span>}
                    </div>
                  )
                default:
                  throw new Error(`Unknown mime type: ${file.mimeType}`)
              }
            }) }
          </Carousel>
        </div>
      </Modal>
    )
  }
})

PrewievModalImages.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  lot: PropTypes.object,
  preview: PropTypes.bool,
  numberOfSlides: PropTypes.number
}

export default PrewievModalImages
