import moment from 'moment'

import ServerTime from '../services/ServerTime'
import { momentTz } from './index'
import {
  AUCTION_ADD_LIMIT,
  AUCTION_REOFFER_LIMIT
} from '../constants'

const epochStart = moment(0)

export function getDateDays (dateString) {
  return moment(momentTz(dateString)._d).diff(epochStart, 'days')
}

export function formatAuctionDate (date) {
  return momentTz(date).format('dddd D MMMM YYYY')
}

export function getSectionHeaderTitle (auctionDate, today) {
  const todayDays = today ? getDateDays(today) : getDateDays(new Date())
  const days = getDateDays(auctionDate)
  if (days === todayDays) {
    return 'Today'
  } else if (days === todayDays + 1) {
    return 'Tomorrow'
  } else {
    return formatAuctionDate(auctionDate)
  }
}

export function isAvailableAuction (auction) {
  if (!auction) {
    return false
  }
  if (auction.cancelled) {
    return false
  }
  const leftDate = moment(ServerTime.getTime()).add(AUCTION_ADD_LIMIT, 'days')
  const rightDate = new Date(auction.editLotDisabledAt)
  const liveAt = new Date(auction.liveAt).getTime()
  return auction.state === 'future' && (liveAt < leftDate && ServerTime.getTime() < rightDate)
}

export function isAvailableReofferAuction (auction) {
  if (!auction) {
    return false
  }
  if (auction.cancelled) {
    return false
  }
  const leftDate = moment(ServerTime.getTime()).add(AUCTION_REOFFER_LIMIT, 'days')
  const rightDate = new Date(auction.editLotDisabledAt)
  const liveAt = new Date(auction.liveAt)
  return auction.state === 'future' && (liveAt < leftDate && ServerTime.getTime() < rightDate)
}

export const getLotNumber = (auction, lot) => {
  let lotNumber
  if (auction) {
    const auctionOpen =
      auction.state === 'open' ||
      auction.state === 'live' ||
      auction.state === 'closed'
    lotNumber =
      ((!auctionOpen && auction.showLotNumbers) || auctionOpen) && lot.number
        ? `Lot ${lot.number}`
        : lot.searchNumericId
          ? `ID${lot.searchNumericId.substr(2, lot.searchNumericId.length)}`
          : 'Lot X'
  } else {
    lotNumber = lot.searchNumericId
      ? `ID${lot.searchNumericId.substr(2, lot.searchNumericId.length)}`
      : 'Lot X'
  }
  return lotNumber
}
