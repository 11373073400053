import React from 'react'
import ReactDOM from 'react-dom'
import { persistStore } from 'redux-persist'
import * as Sentry from '@sentry/react'
import store from './services/store'
import App from './AppContainer'

Sentry.init({ dsn: 'https://3ae8dc4e4e5a4024bd05219935bf2909@o267887.ingest.sentry.io/5304690' })
persistStore(store, null, () => {
  ReactDOM.render(
    (
      <App store={store} />
    ),
    document.getElementById('root')
  )
})
