import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

import Api from '../../services/api'
import { BASE_URL } from '../../config'
import ReactGA from 'react-ga'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faTimes,
  faArrowDown
} from '@fortawesome/fontawesome-free-solid'

class EmailModalAuction extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      recipientName: this.props.userData.firstName,
      recipientEmail: this.props.userData.email,
      docType: '' // enum ['summary', 'full']
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.sendEmailRequest = this.sendEmailRequest.bind(this)
  }

  handleInputChange ({ target: { name, value } }) {
    this.setState({
      [name]: value
    })
  }

  async sendEmailRequest (docType) {
    const data = {
      recipientName: this.state.recipientName,
      recipientEmail: this.state.recipientEmail,
      docType: docType
    }
    ReactGA.event({
      category: 'Click on Send Email on WordPress site',
      action: `${docType === 'summary' ? 'Summary Catalogue' : 'Lots in Full Detail'}`
    })
    this.props.showSpinner(true)
    const res = await Api.emailAuctionInfo({
      auctionId: this.props.auction._id,
      data
    })
    if (res && res.success) {
      this.props.showModal({
        title: 'FarmGate',
        message: res.success
      })
      this.props.closeModal()
    }
    this.props.showSpinner(false)
  }

  render () {
    const downloadModal = this.props.downloadModal
    return (
      <Modal
        isOpen={this.props.isOpen}
        onAfterOpen={this.props.afterOpenModal}
        onRequestClose={this.props.closeModal}
        ariaHideApp={false}
        // shouldCloseOnOverlayClick={false}
        overlayClassName='overlay'
        className='content'
        portalClassName='ReactModalPortal-Email'
      >
        <h2 className='modal-header'>{downloadModal ? 'Download Results' : this.props.closed ? 'Email Results' : 'Email Catalogue'}</h2>
        <span className='icon-close icon-danger' onClick={this.props.closeModal}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <div className={downloadModal ? 'download modal-body' : 'modal-body'}>
          {
            downloadModal
              ? (
                <h3 className='choice-desc'>Please choose if you would like to download a summary of the catalogue or the
                  lots in
                  full detail.</h3>
              )
              : (
                <React.Fragment>
                  <input
                    type='text'
                    name='recipientName'
                    placeholder='Name'
                    className='modal-input'
                    onChange={this.handleInputChange}
                    defaultValue={this.state.recipientName} />
                  <input
                    type='text'
                    name='recipientEmail'
                    placeholder='Email'
                    className='modal-input'
                    onChange={this.handleInputChange}
                    defaultValue={this.state.recipientEmail} />
                  <h3 className='choice-desc'>Please choose if you would like to email a summary of the catalogue or the
                    lots in
                    full detail.</h3>
                </React.Fragment>
              )
          }

          <div className='buttons'>
            {
              downloadModal
                ? (
                  <React.Fragment>
                    <a className='button' href={`${BASE_URL}print/auctions/${this.props.auction.searchNumber}/auction-summary?type=pdf`}>
                      <span className='button-text'>Summary Catalogue</span>
                      <span className='icon-wrapper'><FontAwesomeIcon icon={faArrowDown} /></span>
                    </a>
                    <a className='button' name='fullDetail' href={`${BASE_URL}print/auctions/${this.props.auction.searchNumber}/full-details`}>
                      <span className='button-text'>Lots in Full Detail</span>
                      <span className='icon-wrapper'><FontAwesomeIcon icon={faArrowDown} /></span>
                    </a>
                  </React.Fragment>
                )
                : (
                  <React.Fragment>
                    <div className='button' onClick={this.sendEmailRequest.bind(null, 'summary')}>
                      <span className='button-text'>Summary Catalogue</span>
                      <span className='icon-wrapper'><FontAwesomeIcon icon={faEnvelope} /></span>
                    </div>
                    <div className='button' name='fullDetail' onClick={this.sendEmailRequest.bind(null, 'full')}>
                      <span className='button-text'>Lots in Full Detail</span>
                      <span className='icon-wrapper'><FontAwesomeIcon icon={faEnvelope} /></span>
                    </div>
                  </React.Fragment>
                )
            }
          </div>
        </div>
      </Modal>
    )
  }
}

EmailModalAuction.propTypes = {
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  afterOpenModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  auction: PropTypes.object
}

EmailModalAuction.defaultProps = {
  afterOpenModal: () => {}
}

export default EmailModalAuction
