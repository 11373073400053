import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck } from '@fortawesome/fontawesome-free-solid'
import Range from 'rc-slider/lib/Range'
import { setRangeValue } from '../../utils/Filter'
import { isElementInsideOf, checkFilterIntersection } from '../../utils'

export default class FilterRangeAge extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      converValueMinYear: (this.props.value && this.props.value[0])
        ? (this.props.value[0] % 12 === 0)
          ? (this.props.value[0] / 12)
          : (this.props.value[0] / 12).toFixed(1)
        : (this.props.min % 12 === 0) ? this.props.min / 12 : (this.props.min / 12).toFixed(1),

      converValueMaxYear: ((this.props.value && this.props.value[1] % 12 === 0) || this.props.max % 12 === 0)
        ? (this.props.value && this.props.value[1] / 12) || this.props.max / 12
        : ((this.props.value && this.props.value[1] / 12) || this.props.max / 12).toFixed(1),
      converValueMinMnth: (this.props.value && this.props.value[0]) || this.props.min,
      converValueMaxMnth: (this.props.value && this.props.value[1]) || this.props.max,
      isOpen: false,
      inColumn: false,
      value: this.props.value || [],
      selectedRangeItem: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.apply = this.apply.bind(this)
    this.clear = this.clear.bind(this)
    this.open = this.open.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
    this.onResize = this.onResize.bind(this)
    this.onFocusInput = this.onFocusInput.bind(this)
    this.changeValueMinYear = this.changeValueMinYear.bind(this)
    this.changeValueMinMnth = this.changeValueMinMnth.bind(this)
    this.changeValueMaxYear = this.changeValueMaxYear.bind(this)
    this.changeValueMaxMnth = this.changeValueMaxMnth.bind(this)
    this.onBlurMaxYearsOrMontsInputs = this.onBlurMaxYearsOrMontsInputs.bind(this)

    this.innerContainer = React.createRef()
    this.inputRef = React.createRef()
    this.inputRef2 = React.createRef()
    this.inputRef3 = React.createRef()
    this.inputRef4 = React.createRef()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.max !== this.props.max || prevProps.min !== this.props.min) {
      if (prevState.value.length) {
        this.setState({
          converValueMaxYear: prevState.value[1] % 12 === 0 ? prevState.value[1] / 12 : (prevState.value[1] / 12).toFixed(1),
          converValueMaxMnth: prevState.value[1]
        })
      } else {
        this.setState({
          converValueMaxYear: this.props.max % 12 === 0 ? this.props.max / 12 : (this.props.max / 12).toFixed(1),
          converValueMaxMnth: this.props.max
        })
      }
    }

    this.onResize()
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value
      })
    }
    if (prevState.isOpen !== this.state.isOpen) {
      if (this.state.isOpen) {
        this.onResize()
        document.addEventListener('click', this.handleDocumentClick)
        window.addEventListener('resize', this.onResize)
      } else {
        document.removeEventListener('click', this.handleDocumentClick)
        window.removeEventListener('resize', this.onResize)
      }
    }
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.handleDocumentClick)
    window.removeEventListener('resize', this.onResize)
  }

  onResize () {
    window.innerWidth <= 1650 ? this.displayInColumn(true) : this.displayInColumn(false)
    const innerContainer = this.innerContainer.current
    checkFilterIntersection(innerContainer, 'filter-range-inner-right')
  }

  displayInColumn (inColumn) {
    this.setState({ inColumn })
  }

  handleChange (value) {
    let { selectedRangeItem } = this.state
    if (value[0] !== this.state.converValueMinMnth) {
      selectedRangeItem = 'min'
    } else if (value[1] !== this.state.converValueMaxMnth) {
      selectedRangeItem = 'max'
    }
    const [converValueMinMnth, converValueMaxMnth] = setRangeValue(value, this.props.max, selectedRangeItem, window.innerWidth)
    if (this.state.inColumn !== value) {
      this.setState({
        converValueMinMnth,
        converValueMaxMnth,
        converValueMinYear: converValueMinMnth % 12 === 0 ? converValueMinMnth / 12 : (converValueMinMnth / 12).toFixed(1),
        converValueMaxYear: converValueMaxMnth % 12 === 0 ? converValueMaxMnth / 12 : (converValueMaxMnth / 12).toFixed(1),
        value: [converValueMinMnth, converValueMaxMnth],
        selectedRangeItem
      })
    }
  }

  onFocusInput (ref) {
    ref.current.focus()
    ref.current.value = ''
  }

  changeValueMinYear (event) {
    this.setState({
      selectedRangeItem: 'min',
      converValueMinYear: !isNaN(event.target.value) ? +event.target.value >= this.state.converValueMaxYear ? this.state.converValueMaxYear : +event.target.value : 0,
      converValueMinMnth: !isNaN(event.target.value) ? +event.target.value >= this.state.converValueMaxYear ? this.state.converValueMaxMnth : parseInt(event.target.value * 12, 10) : 0,
      value: update(this.state.value, {
        0: { $set: +event.target.value >= this.state.converValueMaxYear ? this.state.converValueMaxMnth : +event.target.value * 12 },
        1: { $set: this.state.converValueMaxMnth }
      })
    })
  }

  changeValueMinMnth (event) {
    this.setState({
      selectedRangeItem: 'min',
      converValueMinMnth: !isNaN(event.target.value) ? +event.target.value >= this.state.converValueMaxMnth ? this.state.converValueMaxMnth : +event.target.value : 0,
      converValueMinYear: !isNaN(event.target.value) ? +event.target.value <= this.props.max && (+event.target.value % 12 === 0)
        ? +event.target.value / 12
        : +event.target.value >= this.state.converValueMaxMnth
          ? this.state.converValueMaxMnth % 12 === 0
            ? this.state.converValueMaxMnth / 12
            : (this.state.converValueMaxMnth / 12).toFixed(1)
          : (+event.target.value / 12).toFixed(1) : 0,
      value: update(this.state.value, {
        0: { $set: +event.target.value >= this.state.converValueMaxMnth ? this.state.converValueMaxMnth : +event.target.value },
        1: { $set: this.state.converValueMaxMnth }
      })
    })
  }

  changeValueMaxYear (event) {
    this.setState({
      selectedRangeItem: 'max',
      converValueMaxYear: !isNaN(event.target.value) ? +event.target.value <= this.props.max && (this.props.max % +event.target.value === 0)
        ? parseInt(event.target.value, 10)
        : +event.target.value >= parseInt(this.props.max / 12, 10)
          ? parseInt(this.props.max / 12, 10)
          : +event.target.value
        : parseInt(this.props.max / 12, 10),
      converValueMaxMnth: !isNaN(event.target.value) ? +event.target.value >= (this.props.max / 12).toFixed(1) ? this.props.max : parseInt(event.target.value * 12, 10) : this.props.max,
      value: update(this.state.value, {
        0: { $set: this.state.converValueMinMnth },
        1: { $set: !isNaN(event.target.value) ? +event.target.value <= this.state.converValueMinYear ? this.state.converValueMinYear * 12 : +event.target.value * 12 : this.props.max }
      })
    })
  }

  changeValueMaxMnth (event) {
    this.setState({
      selectedRangeItem: 'max',
      converValueMaxMnth: !isNaN(event.target.value)
        ? +event.target.value >= this.props.max
          ? this.props.max
          : +event.target.value
        : this.props.max,

      converValueMaxYear: !isNaN(event.target.value)
        ? +event.target.value <= this.props.max && (+event.target.value % 12 === 0)
          ? parseInt(+event.target.value / 12, 10)
          : +event.target.value <= this.state.converValueMinMnth
            ? (+event.target.value / 12).toFixed(1)
            : +event.target.value >= this.props.max
              ? (this.props.max % 12 === 0)
                ? parseInt(this.props.max / 12, 10)
                : (this.props.max / 12).toFixed(1)
              : (+event.target.value / 12).toFixed(1)
        : (this.props.max / 12).toFixed(1),

      value: update(this.state.value, {
        0: { $set: this.state.converValueMinMnth },
        1: { $set: !isNaN(event.target.value) ? +event.target.value >= this.props.max ? this.props.max : +event.target.value <= this.state.converValueMinMnth ? this.state.converValueMinMnth : +event.target.value : this.props.max }
      })
    })
  }

  apply () {
    this.props.onChange(this.props.name, this.state.value)
    this.setState({ isOpen: false })
  }

  clear () {
    this.setState({
      converValueMinYear: 0,
      converValueMaxYear: ((this.props.value && this.props.value[1] % 12 === 0) || this.props.max % 12 === 0)
        ? (this.props.value && this.props.value[1] / 12) || this.props.max / 12
        : ((this.props.value && this.props.value[1] / 12) || this.props.max / 12).toFixed(1),
      converValueMinMnth: this.props.min,
      converValueMaxMnth: this.props.max
    })
    this.props.onChange(this.props.name, [])
  }

  open () {
    if (!this.state.isOpen) this.setState({ isOpen: true })
  }

  handleDocumentClick (event) {
    if (!this.state.isOpen ||
      isElementInsideOf(event.target, this.innerContainer.current)) {
      return
    }
    this.setState({ isOpen: false })
  }

  onBlurMaxYearsOrMontsInputs (selector) {
    const { converValueMaxMnth, converValueMinMnth } = this.state
    if (converValueMaxMnth < converValueMinMnth) {
      if (selector === 'max') {
        this.setState({
          converValueMaxMnth: converValueMinMnth,
          converValueMaxYear: (converValueMinMnth / 12).toFixed(1),
          value: update(this.state.value, {
            0: { $set: this.state.converValueMinMnth },
            1: { $set: converValueMinMnth }
          })
        })
      } else if (selector === 'min') {
        this.setState({
          converValueMinMnth: this.props.min,
          converValueMinYear: (this.props.min / 12).toFixed(1),
          value: update(this.state.value, {
            0: { $set: this.props.min },
            1: { $set: this.state.converValueMaxMnth }
          })
        })
      }
    }
  }

  render () {
    const {
      description,
      belowDescription,
      title,
      icon,
      min,
      max,
      unitY,
      unitM,
      buttonLg
    } = this.props
    const { isOpen, inColumn, value, selectedRangeItem } = this.state
    // only display as active if has value, and its not the default one (min and max)
    const isActive = Boolean(isOpen ||
      (this.props.value.length && (min !== this.props.value[0] || max !== this.props.value[1])))
    return (
      <div
        className={`filter-range-wrapper filter-range-wrapper-md ${buttonLg ? 'filter-range-wrapper-lg' : ''}`}>
        <div className={`btn filter-btn filter-btn-md ${buttonLg ? 'filter-btn-lg' : ''} ${isOpen ? 'btn-opened' : ''} ${isActive ? 'filter-btn-active' : ''}`}
          onClick={this.open}
        >
          <span>{title}</span>
          <span className='icon-wrapper'>{icon}</span>
        </div>
        {
          isOpen &&
          <div ref={this.innerContainer}
            className={`filter-range-inner ${inColumn ? 'in-column' : ''}`}>
            <p>{description}</p>
            <div className={`slider-wrapper age ${inColumn ? 'in-column' : ''}`}>
              <div className='slider-wrapper-left'>
                <span onClick={() => this.onFocusInput(this.inputRef)} className={`slider-counter-age slider-counter-age-left ${inColumn ? 'in-column' : ''}`}>
                  <input
                    ref={this.inputRef}
                    className='inputAge'
                    type='text'
                    name='converValueMinYear'
                    value={this.state.converValueMinYear.toString()}
                    placeholder={this.state.converValueMinYear}
                    onChange={this.changeValueMinYear}
                    onBlur={() => {
                      this.onBlurMaxYearsOrMontsInputs('min')
                    }}
                  /> <span className='text-mounth'>{unitY}</span>
                </span>
                <p className='slider-text'>or</p>
                <span onClick={() => this.onFocusInput(this.inputRef3)} className={`slider-counter-age slider-counter-age-right ${inColumn ? 'in-column' : ''}`}>
                  <input
                    ref={this.inputRef3}
                    className='inputAge'
                    type='text'
                    name='converValueMaxYear'
                    value={this.state.converValueMaxYear.toString()}
                    placeholder={this.state.converValueMaxYear}
                    onChange={this.changeValueMaxYear}
                    onBlur={() => {
                      this.onBlurMaxYearsOrMontsInputs('max')
                    }}
                  /> <span className='text-mounth'>{unitY}</span>
                </span>
              </div>
              <Range
                className='range-age'
                min={min}
                max={max}
                allowCross={false}
                value={value.length ? setRangeValue(value, max, selectedRangeItem, window.innerWidth) : [min, max]}
                onChange={this.handleChange}
              />
              <div className='slider-wrapper-right'>
                <span onClick={() => this.onFocusInput(this.inputRef2)} className={`slider-counter-age slider-counter-age-left ${inColumn ? 'in-column' : ''}`}>
                  <input
                    ref={this.inputRef2}
                    className='inputAge'
                    type='text'
                    name='converValueMinMnth'
                    value={Math.ceil(this.state.converValueMinMnth).toString()}
                    placeholder={Math.ceil(this.state.converValueMinMnth)}
                    onChange={this.changeValueMinMnth}
                    onBlur={() => {
                      this.onBlurMaxYearsOrMontsInputs('min')
                    }}
                  /> <span className='text-mounth'>{unitM}</span>
                </span>
                <p className='slider-text'>or</p>
                <span onClick={() => this.onFocusInput(this.inputRef4)} className={`slider-counter-age slider-counter-age-right ${inColumn ? 'in-column' : ''}`}>
                  <input
                    ref={this.inputRef4}
                    className='inputAge'
                    type='text'
                    name='converValueMaxMnth'
                    value={Math.ceil(this.state.converValueMaxMnth).toString()}
                    placeholder={Math.ceil(this.state.converValueMaxMnth)}
                    onBlur={() => {
                      this.onBlurMaxYearsOrMontsInputs('max')
                    }}
                    onChange={this.changeValueMaxMnth}
                  /> <span className='text-mounth'>{unitM}</span>
                </span>
              </div>
            </div>
            <div className='below-description'>
              <p>{belowDescription}</p>
            </div>
            <div className='range-buttons'>
              <div
                className='btn filter-btn filter-btn-sm'
                onClick={this.apply}
              >
                <span>Apply</span>
                <span className='icon-wrapper icon-green'><FontAwesomeIcon icon={faCheck} /></span>
              </div>
              <div
                className='btn filter-btn filter-btn-sm'
                onClick={this.clear}
              >
                <span>Clear</span>
                <span className='icon-wrapper icon-danger'><FontAwesomeIcon icon={faTimes} /></span>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

FilterRangeAge.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  unitY: PropTypes.string.isRequired,
  unitM: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  icon: PropTypes.element.isRequired,
  buttonLg: PropTypes.bool
}
