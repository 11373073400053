import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export default class BackButton extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const { name, onPress } = this.props
    return (
      <div className='back-btn' onClick={onPress}>
        <span className='icon-wrapper icon-success'><FontAwesomeIcon icon={faArrowLeft} /></span>
        <span>{name}</span>
      </div>
    )
  }
}

BackButton.defaultProps = {
  onPress: () => {},
  name: 'Back'
}

BackButton.propTypes = {
  onPress: PropTypes.func,
  name: PropTypes.string
}
