import React, { Component, Fragment } from 'react'
import Loader from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlayCircle
} from '@fortawesome/fontawesome-free-solid'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

export default class ImageLoader extends Component {
  constructor () {
    super()
    this.state = {
      loading: true
    }
  }

  render () {
    let { src, alt, onError, id, className, file } = this.props
    if ((className.includes('video-thumbnail') || className.includes('carousel-video')) && file) {
      src = file.thumbnail
      alt = 'video'
    }
    return (
      <Fragment>
        <div className='video-image-wrapper'>
          {(className.includes('video-thumbnail') || className.includes('carousel-video')) && <FontAwesomeIcon style={{ color: '#fff' }} icon={faPlayCircle} />}
          <img onLoad={() => {
            this.setState({
              loading: false
            })
          }} className={className} src={src} id={id} alt={alt} onError={onError} />
        </div>
        
        {this.state.loading &&
        <Loader
          className='image-loader-spinner'
          type='Oval'
          color='black'
          height={80}
          width={80}
        />
        }
      </Fragment>
    )
  }
}
