import update from 'immutability-helper'

import {
  CHANGE_STATE_PROP,
  MERGE_STATE_PROP,
  FILTER_STATE_PROP,
  USER_LOGOUT,
  CLOSE_USER_NOTIFICATION
} from '../constants/index'
import {
  mergeByProperty,
  pushToUniqueArray,
  limitArray
} from '../utils/index'

const REDUCER = 'USER'

/*
  This reducer is meant for data that should be RESET when user logs out
  if you need to keep data persisted even after logout/login, use 'main' reducer instead
  if you do not need to persist data, use 'temp' reducer instead
*/

const initialState = {
  data: {},
  bids: [],
  autobids: [],
  pics: [],
  filters: [],
  rememberMe: true,
  loggedIn: false,
  userNotifications: {
    auctionLive: {
      closed: []
    },
    auctionOpen: {
      closed: []
    }
  }
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case REDUCER + CHANGE_STATE_PROP:
      return update(state, {
        [action.state.prop]: { $set: action.state.value }
      })
    case REDUCER + MERGE_STATE_PROP:
      let mergeProp
      switch (action.state.prop) {
        case 'filters':
          mergeProp = 'screen'
          break
        case 'bids':
        case 'autobids':
          mergeProp = 'lot'
          break
        default:
          mergeProp = '_id'
      }
      return update(state, {
        [action.state.prop]: (prop) => {
          let res = mergeByProperty(prop, action.state.value, mergeProp)
          if (action.state.prop === 'filters') {
            res = limitArray(res, 100)
          }
          return res
        }
      })
    case REDUCER + FILTER_STATE_PROP:
      return update(state, {
        [action.state.prop]: (prop) => prop.filter(action.state.func)
      })
    case USER_LOGOUT:
      return update(state, {
        $set: initialState
      })
    // close notification (can not be shown again, but resets when user logs out)
    case CLOSE_USER_NOTIFICATION:
      return update(state, {
        userNotifications: {
          [action.notificationType]: {
            closed: (prop) => limitArray(pushToUniqueArray(prop, [action.key]), 100)
          }
        }
      })
    default:
      return state
  }
}
